import { LoadingButton, Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { AxiosError } from "axios";

import { BottomSheet } from "../../components/BottomSheet";
import { DialogFooter } from "../../components/DialogFooter";
import { useDeleteResume } from "../api/useDeleteResume";
import { type PlacementCandidate } from "../types";

interface DeleteCandidateResumeModalProps {
  placementCandidate: PlacementCandidate;
  onResumeDeleted: () => Promise<void>;
  modalState: UseModalState;
}

export function DeleteCandidateResumeModal(props: DeleteCandidateResumeModalProps) {
  const { placementCandidate, modalState, onResumeDeleted } = props;
  const { showErrorToast } = useToast();
  const { mutateAsync: deleteResume, isLoading: isDeletingResume } = useDeleteResume({
    onSuccess: async () => {
      modalState.closeModal();
      await onResumeDeleted();
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        showErrorToast(error.message);
      } else {
        showErrorToast("Failed to delete your resume");
      }
    },
  });

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="horizontal"
          onClose={() => {
            if (isDeletingResume) {
              return;
            }

            modalState.closeModal();
          }}
        >
          <LoadingButton
            fullWidth
            variant="contained"
            color="error"
            size="large"
            isLoading={isDeletingResume}
            onClick={async () =>
              await deleteResume({ placementCandidateId: placementCandidate.id })
            }
          >
            Delete Resume
          </LoadingButton>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack spacing={3}>
          <Title component="h3" variant="h5">
            Delete Resume
          </Title>
          <Text variant="body1">
            Are you sure you want to delete your resume? This will also delete your Clipboard
            Passport, and cannot be undone.
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
