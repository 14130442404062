import { LoadingButton, Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent, Stack } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib";
import { Divider } from "@src/appV2/redesign/components/Divider";
import { IconButton } from "@src/appV2/redesign/components/IconButton";
import { useForm } from "react-hook-form";

import { useCreateResume } from "../api/useCreateResume";
import {
  type CreateCandidateResumeSchema,
  createCandidateResumeSchema,
  type PlacementCandidate,
} from "../types";
import { CreateCandidateResumeForm } from "./CreateCandidateResumeForm";

interface CreateCandidateResumeModalProps {
  placementCandidate: PlacementCandidate;
  onResumeUploaded: () => Promise<void>;
  modalState: UseModalState;
}

export function CreateCandidateResumeModal(props: CreateCandidateResumeModalProps) {
  const { placementCandidate, modalState, onResumeUploaded } = props;
  const form = useForm<CreateCandidateResumeSchema>({
    resolver: zodResolver(createCandidateResumeSchema),
    defaultValues: {
      experience: [],
      education: [],
    },
  });
  const { mutateAsync: createResume, isLoading: isCreatingResume } = useCreateResume();

  async function onSubmit(data: CreateCandidateResumeSchema) {
    await createResume({
      placementCandidateId: placementCandidate.id,
      profile: data,
    });
    modalState.closeModal();
    await onResumeUploaded();
  }

  return (
    <FullScreenDialog modalState={modalState}>
      <DialogContent>
        <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
          <IconButton
            iconType="arrow-left"
            variant="outlined"
            size="large"
            onClick={modalState.closeModal}
          />
        </Stack>

        <Stack spacing={2}>
          <Title component="h3" variant="h3">
            Create Resume
          </Title>
          <Text variant="body2">Enter your information, and we will create a resume for you.</Text>

          <CreateCandidateResumeForm form={form} />

          <Divider />

          <LoadingButton
            variant="contained"
            isLoading={isCreatingResume || form.formState.isSubmitting}
            onClick={form.handleSubmit(onSubmit)}
          >
            Create Resume
          </LoadingButton>
        </Stack>
      </DialogContent>
    </FullScreenDialog>
  );
}
